import React  from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { Box, Button, Image, Paragraph, ResponsiveContext, Heading } from 'grommet';
// import { linkResolver } from '@pwc-new-ventures/ui';
import Layout from '../components/Healthcheck/layouts';


export default function({ primary }) {
  const { contentfulAccountingHealthCheckPage } = useStaticQuery(graphql`
    {
      contentfulAccountingHealthCheckPage(slug: {eq: "healthcheck"}) {
        metaTitle
        metaDescription
        image {
          ...ImageSharpMedium
        }
      }
    }
  `);

  const doc = {
    meta: {
      title: contentfulAccountingHealthCheckPage.metaTitle,
      description: contentfulAccountingHealthCheckPage.metaDescription,
      image:
        contentfulAccountingHealthCheckPage.image &&
        contentfulAccountingHealthCheckPage.image.localFile.childImageSharp.fluid.src,
    },
  };
  const background = '#2D2D2D';
  
  const HeadingContainer = styled(Box).attrs(({ background }) => ({
    background,
    pad: { vertical: 'large'},
    direction: 'row',
    justify: 'center'
  }))``;
  
  const InnerContainer = styled(Box)`
    max-width: 1180px;
    padding: 0 20px;
    width: 100%;
  `;
  
  const Title = styled(Heading).attrs({
    level: 2,
    margin: {
      vertical: 'none',
    }
  })``;
  
  const Subtitle = styled(Paragraph)`
    margin: 0
  `;

  return (
    <Layout title={doc.meta.title} description={doc.meta.description} image={doc.meta.image}>
      <ResponsiveContext.Consumer>
        {(size) => {
          const isSmall = size !== 'medium' && size !== 'large';
          return (
            <>
              <HeadingContainer background={background}>
                <InnerContainer>
                  <Title>Accounting Health Check</Title>
                  <Subtitle fill>
                  Is accounting helping your business perform?
                  </Subtitle>
                  </InnerContainer>
              </HeadingContainer>
              <Box
                margin='0 auto'
                fill
                width={{
                  max: '1180px'
                }}
                direction="row-responsive"
                justify="between"
                pad={{ horizontal: '20px' }}
                background={{
                  "repeat": "no-repeat",
                  "size": "40%",
                  "position": "top 40px right",
                  "image": "url(//images.ctfassets.net/gzpz2xecclqo/29MQ1LuRfczfsiS0IGadUt/e039532b75a7437f1c7793722e70fee4/clouds.svg)"
                }}
                >
                <Box
                  height={{
                    min: '50vh'
                  }}
                  direction="column"
                  style={{
                    marginTop: 20 + 'px'
                  }}
                >
                  <Heading
                  level='3'  
                  style={{
                    maxWidth: 640 + 'px'
                  }}
                  >Get a quick ‘accounting health’ assessment for your mid-sized or privately owned business with our short, easy quiz.
                  </Heading>
                  <Paragraph
                    margin={{
                      bottom: '40px'
                    }}
                    style={{
                      maxWidth: 640 + 'px'
                    }}>Determine how strong your company’s bookkeeping is and reveal areas for potential improvement. Learn more about our simple solution to help get your books in order so you can focus on managing your business.</Paragraph>
                    <Box
                      width="200px"
                    >
                      <Button 
                        label="Let's go" 
                        href='/healthcheck-app'
                        maxWidth="200px"
                        background="#D04A02"
                        primary
                        onClick={() => {
                          if (window.dataLayer) window.dataLayer.push({ event: 'healthcheck-step-letsgo' });
                        }}
                        />
                    </Box>
                </Box>
              </Box>
              <Box
                margin='0 auto'
                fill
                width={{
                  max: '1180px'
                }}
                pad={{ horizontal: '20px' }}
                direction={isSmall  ? "column-reverse" : "row"}
                justify="between"
              >
                <Box
                  width={{
                    max: '480px'
                  }}
                  direction="row"
                  alignContent="end"
                  wrap="true"
                >
                  <Paragraph
                    size="xsmall"
                    >
                    Solicitation © 2022 PwC. All rights reserved. PwC refers to the US member firm or one of its subsidiaries or affiliates, and may sometimes refer to the PwC network. Each member firm is a separate legal entity. Please see www.pwc.com/structure for further details.
                  </Paragraph> 
                </Box>
                <Box>
                  <Image src="//images.ctfassets.net/l07wyut35yzl/5G1Gr0ltaDzv4DfrHAGxD/d96789c2b6e01272584d684354967cbd/io-health-check.svg" />
                </Box>
              </Box>
            </>
          );
        }}
      </ResponsiveContext.Consumer>
    </Layout>
  );
}
